import api from "@/api";

export default {
  state: {
    potentialPolicies: [],
    potentialPolicyToEdit: {},
  },
  getters: {
    potentialPolicies: (state) => state.potentialPolicies,
    potentialPolicyToEdit: (state) => state.potentialPolicyToEdit,
  },
  mutations: {
    setPotentialPolicies: (state, data) => {
      state.potentialPolicies = data;
    },

    setPotentialPolicyToEdit: (state, data) => {
      state.potentialPolicyToEdit = data;
    },
  },
  actions: {
    getPotentialPolicies: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get(
          "/v1/potential-policies"
        );
        const { data } = response.data;
        commit("setPotentialPolicies", data);
      } catch (err) {
        console.error(err);
      }
    },

    createPotentialPolicy: (
      _,
      { customerId, agentId, date, branchCategoryId, priorityId, text, teamId }
    ) =>
      api({ requiresAuth: true }).post("/v1/potential-policies", {
        customer_id: customerId,
        agent_id: agentId,
        date,
        branch_category_id: branchCategoryId,
        priority_id: priorityId,
        text,
        team_id: teamId
      }),

    updatePotentialPolicyAgent: async (_, { potentialPolicyId, agentId }) => {
      try {
        await api({
          requiresAuth: true,
        }).patch(`/v1/potential-policies/${potentialPolicyId}/agents`, {
          agent_id: agentId,
        });
      } catch (err) {
        console.error(err);
      }
    },

    updatePotentialPolicyText: async (_, { potentialPolicyId, text }) => {
      try {
        await api({ requiresAuth: true }).patch(
          `/v1/potential-policies/${potentialPolicyId}/texts`,
          {
            text,
          }
        );
      } catch (err) {
        console.error(err);
      }
    },

    deletePotentialPolicy: async (_, potentialPolicyId) => {
      try {
        await api({ requiresAuth: true }).delete(
          `/v1/potential-policies/${potentialPolicyId}`
        );
      } catch (err) {
        console.error(err);
      }
    },

    addPotentialPolicyFile: async (_, { potentialPolicyId, files }) => {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("potential_policy_files[]", file);
      });
      try {
        await api({ requiresAuth: true }).post(
          `/v1/potential-policies/${potentialPolicyId}/files`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } catch (err) {
        console.error(err);
      }
    },

    updatePotentialPolicy: async (_, { policyId, ...policy }) =>
      api({ requiresAuth: true }).patch(`/v1/potential-policies/${policyId}`, {
        ...policy,
      }),
  },
};
