<template>
  <div class="table">
    <NoData v-if="!customers.length" :type="'customers'" />
    <template v-else>
      <div class="table__header box box--default">
        <div class="table__label table__label--small"></div>
        <div class="table__label table__label--large">
          {{ $t(`customers.${"tableHeader"}.${"name"}`) }}
        </div>
        <div class="table__label table__label--medium">
          {{ $t(`customers.${"tableHeader"}.${"contact"}`) }}
        </div>
        <div class="table__label table__label--large">
          {{ $t(`customers.${"tableHeader"}.${"address"}`) }}
        </div>
        <div class="table__label table__label--small">
          {{ $t(`customers.${"tableHeader"}.${"zipCode"}`) }}
        </div>
        <div class="table__label table__label--medium">
          {{ $t(`customers.${"tableHeader"}.${"city"}`) }}
        </div>
        <div class="table__label table__label--medium">
          {{ $t(`customers.${"tableHeader"}.${"createdAt"}`) }}
        </div>
        <div class="table__label table__label--medium">
          {{ $t(`customers.${"tableHeader"}.${"updatedAt"}`) }}
        </div>
        <div class="table__label table__label--large">
          {{ $t(`customers.${"tableHeader"}.${"insurance"}`) }}
        </div>
        <!-- <div class="table__label table__label--large">
          {{ $t(`customers.${"tableHeader"}.${"company"}`) }}
        </div> -->
        <div class="table__label table__label--large">
          {{ $t(`customers.${"tableHeader"}.${"team"}`) }}
            
        </div>
        <div class="table__label table__label--small"></div>
      </div>

      <div class="table__body">
        <Customer
          v-for="customer in customers"
          :key="customer.id"
          :customer="customer"
        />
      </div>
    </template>
  </div>
</template>

<script>
import Customer from "@/components/Customers/Customer";
import NoData from "@/partials/NoData";
export default {
  name: "CustomersTable",

  components: {
    Customer,
    NoData,
  },

  props: {
    customers: {
      required: true,
      type: Array,
    },
  },
};
</script>

<style scoped lang="scss"></style>
