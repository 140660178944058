<template>
    <div class="customer__form modal modal--default">
      <div class="modal__panel">
        <div class="modal__logo-wrapper">
          <div class="modal__logo"></div>
          <div class="modal__logo-title">The One System</div>
        </div>
  
        <div class="modal__main-icon modal__main-icon--private"></div>
        <div class="modal__close" @click="closeModal()"></div>
  
        <div class="form"> 
  
          <form class="add__form">
            <transition name="fade" mode="out-in">
              <Loading v-if="formLoading" />
              <div v-else class="add__content">
                <transition name="fade" mode="out-in"> 
                  <div v-for="(email, index) in [emailToEdit]" :key="index">
                    <div class="form__field">
                      <input
                        type="text"
                        class="input input--default"
                        placeholder="E-mail"
                        @input="validateEmail($event, index)"
                        
                        v-model="email.email"
                        :class="{
                          'input--error': email.hasError,
                        }"
                      />
                      <div class="form__error" v-if="email.hasError">
                        {{ email.errorPlaceholder }}
                      </div>
                    </div>

                    <div class="form__field">
                      <div class="company">
                        <label class="company__title" for="online_shipping">Onlineversand</label>
                        <div class="checkbox">
                          <input
                            type="checkbox"
                            id="online_shipping"
                            class="checkbox__checkbox"
                            v-model="email.online_shipping"
                          />
                          <label for="online_shipping" class="checkbox__label"></label>
                        </div>
                      </div>
                    </div>
                       
                    <div class="form__field">
                      <input
                        type="text"
                        id="contactPerson"
                        class="input input--default"
                        placeholder="Kontakt Person" 
                        v-model="email.contact_person" 
                      />
                    </div> 
                  </div>  
                </transition>
              </div>
            </transition>
          </form>
        </div>
        <div
          v-if="isRequesting"
          class="modal__button modal__button--submit button button--default button--loading"
        ></div>
        <button
          v-else
          type="button"
          class="modal__button modal__button--submit button button--default"
          @click="updateEmail()"
        >
          <span>{{ $t(`buttons.${"saveBtn"}`) }}</span>
        </button>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  // import moment from "moment";
  import Loading from "@/partials/Loading";
  import statics from "@/statics";
  import parseFieldErrors from "@/helpers/parseFieldErrors";
  import api from "@/api";
  export default {
  name: "EditPrivateEmailModal",
  components: {
    Loading,
  },
  data() {
    return {
      statics,
      parseFieldErrors,
      formLoading: false,
      isRequesting: false,
      emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      editingCustomer: "",
 
      customerEmails: [
        {
          email: "",
          hasError: false,
          errorPlaceholder: "",
          online_shipping: false,
          contact_person: "",
        },
      ],
      emailHasError: false,
      specificErrors: [],
    };
  },
  computed: {
    ...mapGetters([
      "customerForms",
      "privateIsEdit",
      "privateToEdit",
      "customer",
      "emailIdToEdit", 
    ]),
    emailToEdit() {
      return this.customerEmails.find(email => email.id === this.emailIdToEdit) || { email: "" };
    },
  },
  watch: {
    specificErrors(errors) {
      const errorKeys = errors.map((error) => error.errorKey);

      if (errorKeys.includes("email")) {
        this.currentPage = 1;
        errors.forEach((error) => {
          if (error.errorKey === "email") {
            this.customerEmails[error.errorPos].errorPlaceholder =
              error.errorValue;
            this.customerEmails[error.errorPos].hasError = true;
          }
        });
      } else {
        this.customerEmails.forEach((email) => {
          email.errorPlaceholder = "";
          email.hasError = false;
        });
      }
    },
  },
  async mounted() {
    this.formLoading = true;

    await this.$store.dispatch("getCustomerForms");

    if (this.privateIsEdit) {
      const customer = await this.$store.dispatch(
        "getCustomer",
        this.privateToEdit
      );
      this.editingCustomer = customer;
      this.loadFieldsForEdit(customer);
    }
    this.formLoading = false;
  },
  methods: { 
    async updateCustomer() { 
      const { id, entity } = this.$route.params;
      if (entity === "customer" || entity === "potential-customer") {
        this.$store.commit("setCustomerMembers", []);
        await this.$store.dispatch("getGlobalCustomer", id);

        if(this.customer.team){ 
          await this.$store.dispatch("getPolicyForms", this.customer.team.id);
        } 
  
        this.$store.commit("setCustomerDetails", this.customer);
      } else if(entity === "user"){
        try {
          const user = await this.$store.dispatch("getUser", id);
          this.$store.commit("setUser", user);

        } catch (err) {
          console.error(err);
        }
      }else {
        const user = await this.$store.dispatch("getProfile");
        this.$store.commit("setUser", user);
        this.editProfile();
      } 
    },


    async updateEmail(){
      this.isRequesting = true; 
      const customer_emails = {
        email :  this.emailToEdit.email,
        customer_id: this.privateToEdit,
        online_shipping: this.emailToEdit.online_shipping,
        contact_person: this.emailToEdit.contact_person,  
      }  
      

      try {
        await api({requiresAuth: true}).put(`/v1/customer-emails/${this.emailToEdit.id}`, customer_emails).then(() =>{
          this.$store.commit("setToast", {
            display: true,
            type: "success",
            message: "Email edited successfully.",
          });
          this.closeModal();
          this.isRequesting = false;

          this.updateCustomer();
        }
          
        )
          
      } catch (err) {
        console.error(err);
        this.$store.commit("setToast", {
            display: true,
            type: "error",
            message: "Something went wrong.",
          });
          this.closeModal();
          this.isRequesting = false;
      }
      
    },
      
 
    closeModal() {
      this.$store.commit("setshowEditPrivateEmailModal", false);
      this.$store.commit("setPrivateIsEdit", false);   
    },
    loadFieldsForEdit(customer) { 
      this.customerEmails = customer.customer_emails.length
        ? customer.customer_emails.map((email) => ({
            id: email.id,
            email: email.email,
            hasError: false,
            errorPlaceholder: "",
            online_shipping: email.online_shipping,
            contact_person: email.contact_person,
          }))
        : [
            {
              id: "",
              email: "",
              hasError: false,
              errorPlaceholder: "",
              online_shipping: false,
              contact_person: "",
            },
          ]; 
    },
    validateCustomerContact(callback) { 
      this.customerEmails = [...this.customerEmails];
      if (!this.customerEmails.some((email) => email.hasError)) {
        callback(true);
      }
    },
    validateEmail(event, index) {
      const { value } = event.target;
      this.customerEmails[index].email = value;
       
        if (
          this.customerEmails[index].email &&
          !this.emailRegex.test(this.customerEmails[index].email.toLowerCase())
        ) {
          this.customerEmails[index].hasError = true;
          this.customerEmails[index].errorPlaceholder =
            "Invalid email address.";
        } else {
          this.customerEmails[index].hasError = false;
          this.customerEmails[index].errorPlaceholder = "";
        }
       
      this.customerEmails = [...this.customerEmails];
    },
 
  },
};

  </script>
  <style lang="scss" scoped>
    .company {
      display: flex;
      align-items: center;
      margin: 10px 0;

      &__title {
        color: var(--text-2);
        font-family: var(--muli-text-semi-bold);
        margin-right: 10px;
      }
    }

    .modal__panel { 
      width: 500px;
      height: 500px;
    }

    .modal__main-icon {
      scale: 0.8;
    }

    .add__content{
      margin-top: 50px;
    }
  </style>