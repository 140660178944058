<template>
  <div id="app">
    <div class="wrapper">
      <transition
        mode="out-in"
      >
        <router-view></router-view>
      </transition>
      <Toast />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "App",

  data() {
    return {
      isLock: false,
    };
  },

  computed: {
    ...mapGetters(["language", "isDarkMode"]),
  },

  mounted() {
    this.setInitialLanguage();

    this.setDarkMode();

    document.addEventListener("keydown", (event) => {
      if (
        event.key.toLowerCase() === "l" &&
        event.ctrlKey &&
        this.$route.name !== "Lock"
      ) {
        event.preventDefault();
        this.$router.push({
          name: "Lock",
        });
      }
    });
  },

  methods: {
    setInitialLanguage() {
      if (!window.localStorage.getItem("language")) {
        window.localStorage.setItem(
          "language",
          process.env.VUE_APP_I18N_LOCALE
        );
      }
      this.$store.commit(
        "setLanguage",
        window.localStorage.getItem("language")
      );
      this.$root.$i18n.locale = this.language;
    },
  
    setDarkMode(){  
      const savedDarkMode = window.localStorage.getItem("darkMode") === "true"; 
      
      this.$store.commit("setIsDarkMode", savedDarkMode);
 
      document.documentElement.setAttribute(
        "data-theme",
        savedDarkMode ? "dark" : "light"
      );
    }
  },
};
</script>

<style   lang="scss">
 .disabled-el{
  cursor: not-allowed !important;

  .input, input,
  .select, select,
  textarea,
  button{
    cursor: not-allowed !important;
  }

    .select{ 
        &::after {
        background: url("./assets/icons/default/disabled-chevron_down.svg") !important;
        }
    } 
 
    button{
        background-color: #B9C2CD;
    }

    textarea::placeholder {
      color: #7F8FA480 !important;
      }
}

[data-theme="dark"] .disabled-el{  
  button{
    background-color:  #5a6675; 
  }
}


.wrapper {
  overflow-y: hidden;
}
</style>
