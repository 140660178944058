import api from "@/api";

export default {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    terminatePolicy: (
      _,
      { customerId, isDoubleCoverage, text, terminationServices }
    ) =>
      api({ requiresAuth: true }).post("/v1/terminations", {
        customer_id: customerId,
        comment: text,
        double_coverage: isDoubleCoverage,
        termination_services: terminationServices,
      }),

    deleteTerminationService: async (_, serviceId) => {
      try {
        await api({ requiresAuth: true }).delete(
          `/v1/termination-services/${serviceId}`
        );
      } catch (err) {
        console.error(err);
      }
    },

    updateTerminationComment: async (_, { terminationId, comment }) => {
      try {
        await api({ requiresAuth: true }).patch(
          `/v1/terminations/${terminationId}/comments`,
          {
            comment,
          }
        );
      } catch (err) {
        console.error(err);
      }
    },

    uploadTerminationFiles: async (_, { terminationId, files }) => {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("termination_files[]", file);
      });
      try {
        await api({ requiresAuth: true }).post(
          `/v1/terminations/${terminationId}/files`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } catch (err) {
        console.error(err);
      }
    },
 
    updateTermination: async (_, { terminationId, customerId, isDoubleCoverageId, text, terminationServices }) => {
      try {
        await api({ requiresAuth: true }).patch(`/v1/terminations/${terminationId}`, {
          customer_id: customerId,
          comment: text,
          double_coverage: isDoubleCoverageId,
          termination_services: terminationServices,  
        });
      } catch (err) {
        console.error("Update Termination Error:", err.response?.data || err);
        throw err;
      }
    },
     
  },
};
