<template>
  <div
    class="termination"
    :class="[
      { 'animate__animated animate__zoomOutLeft': isDeleting },
      { deleting: wasDeleted },
    ]"
  >
    <div class="row box box--default">
      <div
        class="row__data row__data--medium row__data--title row__data--fixed"
      >
        Kündigung
      </div>
      <div class="row__data row__data--medium">
        <div class="termination__data">{{ category }}</div>
      </div>
      <div class="row__data row__data--medium row__data--underline">
        <span @click="showSubmitDatePopup = true">
            <transition name="fade" mode="in-out">
              <PopupEditer
                v-if="showSubmitDatePopup" 
                :isDate="true"
                @togglePopup="showSubmitDatePopup = $event"
                :selected="terminationDate"
                @submit="updateTerminationDate($event)"
              />
            </transition>
            {{formatDateDisplay(terminationDate)}}
          </span>


        <!-- <div class="termination__data">{{ terminationDate }}</div> -->
      </div> 
      
      
 
      
      <div class="row__data row__data--large">
        <div class="termination__data">{{ companyName }}</div>
      </div>
      <div class="row__data row__data--large">
        <EditableComment
          :comment="comment"
          :isRequired="true"
          :updateComment="updateTerminationComment"
        />
      </div>
      <div class="row__data row__data--medium row__data--half row__data--fixed">
        <div
          class="row__data row__data--small row__data--pdf icon icon__pdf"
          :class="{ 'icon__pdf--active': files.length }"
          @click="openPolicyFilesModal()"
        ></div> 
        <div
          class="row__data row__data--small icon icon__delete"
          @click="deleteTermination()"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
//import { formatDisplayDate } from "@/helpers/dateFormatters";
import EditableComment from "@/partials/EditableComment"; 
import PopupEditer from "@/partials/PopupEditer";
export default {
  name: "TerminationService",

  components: {
    EditableComment, 
    PopupEditer
  },

  props: {
    service: {
      required: true,
      type: Object,
    },

    terminations: {
      required: true,
      type: Array,
    },
    
    // customer:{
    //   required: true,
    //   type: Number
    // },

    // comment:{
    //   required: true,
    //   type: String
    // },

    // doubleCoverage:{
    //   required: true,
    //   type: Boolean
    // },
  },

  data() {
    return {
      isDeleting: false,
      wasDeleted: false,  
      showSubmitDatePopup: false,
    };
  },

  computed: {
    category() {
      return this.service.service.branch_category.code;
    },

    terminationDate() {
      // return formatDisplayDate(this.service.termination_date);
      return this.service.termination_date;
    },

    companyName() {
      return this.service.company.name;
    },

    customer(){
      return this.terminations.find((t) => t.id === this.service.termination_id)
        .customer_id;
    },

    doubleCoverage() {
      return this.terminations.find((t) => t.id === this.service.termination_id)
        .double_coverage;
    },

    comment() {
      return this.terminations.find((t) => t.id === this.service.termination_id)
        .comment;
    },

    files() {
      return this.terminations.find((t) => t.id === this.service.termination_id)
        .termination_files;
    },

    termination(){
      return this.service
    },
 
  },
 
  methods: {
    async deleteTermination() {
      this.isDeleting = true;
      await this.$store.dispatch("deleteTerminationService", this.service.id);
      this.wasDeleted = true;
      setTimeout(async () => {
        await this.getGlobalCustomer();
      }, 300);
    },

    async updateTerminationComment(comment) {
      await this.$store.dispatch("updateTerminationComment", {
        terminationId: this.service.termination_id,
        comment,
      });
      await this.getGlobalCustomer();
    },

    async getGlobalCustomer() {
      const { id } = this.$route.params;
      await this.$store.dispatch("getGlobalCustomer", id);
    },

    openPolicyFilesModal() {
      this.$store.commit("setPolicyFileId", this.service.termination_id);
      this.$store.commit("setPolicyFiles", this.files);
      this.$store.commit("setPolicyFilesModalIsTermination", true);
      this.$store.commit("setShowPolicyFilesModal", true);
    },

    formatDate(date) {
      if (!date || typeof date !== 'string') {
        return null;  
      } 
 
      if (date.includes('-')) {
        const [year, month, day] = date.split('-');
        if (year && month && day && month.length === 2 && day.length === 2) {
          return `${year}-${month}-${day}`;
        }
      } 
   
      const [day, month, year] = date.split('.');
      if (day && month && year && day.length === 2 && month.length === 2 && year.length === 4) {
        return `${year}-${month}-${day}`;
      }

      this.$store.commit("setToast", {
        display: true,
        type: "error",
        message: "Wrong date format!",
      });

      return null; 
    },

    formatDateDisplay(date){
      if (date.includes('-')) {
        const [year, month, day] = date.split('-');
        if (year && month && day && month.length === 2 && day.length === 2) {
          return `${day}.${month}.${year}`;
        }
      } 
      return;
    },

    async updateTerminationDate(submitDate){   
      if(submitDate){  
        const formattedDate = this.formatDate(submitDate);
     
        const service = {
          id: this.service.id,
          company_id: this.service.company.id,
          service_id: this.service.service.id,
          termination_date: formattedDate
        }
        
        try{
          await this.$store.dispatch("updateTermination", {
            terminationId: this.termination.id,
            customerId: this.customer,
            isDoubleCoverageId: this.doubleCoverage,
            text: this.comment,
            terminationServices: [service],  
          });

          this.$store.commit("setToast", {
            display: true,
            type: "success",
            message: "Termination updated successfully.",
          });

          this.showSubmitDatePopup = false; 
          await this.getGlobalCustomer();
        }catch(err){
          this.$store.commit("setToast", {
            display: true,
            type: "error",
            message: "Something went wrong!",
          });

          this.showSubmitDatePopup = false; 
        }
      } else{
        this.$store.commit("setToast", {
          display: true,
          type: "error",
          message: "Select a date first!",
        });

        this.showSubmitDatePopup = false; 
      }
    },
    },
};
</script>

<style lang="scss" scoped>
.termination {
  border-radius: var(--radius-1);
  transition: all 0.3s ease;
  max-height: 66px;
  margin-bottom: 15px;
  position: relative;

  .row {
    background: var(--bg-3);
    box-shadow: none;
    cursor: default;
    margin-bottom: 0;

    &__data {
      justify-content: flex-start;
      &--title {
        color: var(--text-12);
        font-size: var(--medium);
      }
    }

    &__data--half {
      flex-direction: row !important;
    }
  }

  &__data {
    color: var(--text-9);
  }
}

[data-theme="dark"] .termination{
  background: var(--bg-14);
}
</style>
