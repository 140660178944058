import api from "@/api";
import router from "@/router";

export default {
  state: {
    customers: [],
    customersPerPage: {},
    currentCustomersPage: 0,
    customerDetails: {},
    customer: {},
    customerMembers: [],
  },
  getters: {
    customers: (state) => state.customers,
    customersPerPage: (state) => state.customersPerPage,
    currentCustomersPage: (state) => state.currentCustomersPage,
    customerDetails: (state) => state.customerDetails,
    customer: (state) => state.customer,
    customerMembers: (state) => state.customerMembers,
  },
  mutations: {
    setCustomers: (state, data) => {
      state.customers = data;
    },
    setCustomersPerPage: (state, data) => {
      state.customersPerPage = data;
    },
    setCurrentCustomersPage: (state, data) => {
      state.currentCustomersPage = data;
    },
    setCustomerDetails: (state, data) => {
      state.customerDetails = data;
    },
    setCustomer: (state, data) => {
      state.customer = data;
    },
    setCustomerMembers: (state, data) => {
      state.customerMembers = data;
    },
  },
  actions: {
    getCustomer: (_, customerId) =>
      new Promise((resolve, reject) => {
        api({ requiresAuth: true })
          .get(`/v1/customers/${customerId}`)
          .then(
            (response) => {
              const { data } = response.data;
              resolve(data);
            },
            (err) => {
              console.error(err);
              reject();
            }
          );
      }),

    getGlobalCustomer: async ({ commit }, customerId) => {
      try {
        const response = await api({ requiresAuth: true }).get(
          `/v1/customers/${customerId}`
        );
        const { data } = response.data;
        commit("setCustomer", data);
        const { members, parent_id } = data;
        if (members.length) {
          commit("setCustomerMembers", [data, ...members]);
        } else if (parent_id && !members.length) {
          const response = await api({ requiresAuth: true }).get(
            `/v1/customers/${data.parent_id}`
          );
          const { data: customer } = response.data;
          const { members } = customer;
          commit("setCustomerMembers", [customer, ...members]);
        }
      } catch (err) {
        console.error(err);
      }
    },

    addCustomer: (
      { commit, dispatch },
      {
        companyName,
        companyType,
        language,
        customerAddresses,
        web,
        uid,
        customer_emails,
        customer_phones,
        name,
        account,
        address,
        member_type_id,
        gender,
        titleId,
        firstName,
        lastName,
        birthday,
        countryCode,
        ahvNr,
        occupation,
        employer,
        file,
        parentId,
        familyStatusId,
        teamId
      }
    ) =>
      new Promise((resolve, reject) => {
        commit("setCustomersLoading", true);
        const { number, street, zip, city, postBox } = customerAddresses;
        const customer_addresses = [
          { number, street, zip, city, post_box: postBox },
        ];
        api({ requiresAuth: true })
          .post("/v1/customers", {
            company_name: companyName,
            company_type_id: companyType,
            language_id: language,
            customer_addresses,
            web,
            uuid: uid,
            customer_emails,
            customer_phones,
            member_type_id,
            gender_id: gender,
            title_id: titleId,
            first_name: firstName,
            last_name: lastName,
            birthday,
            country_code: countryCode,
            ahv_number: ahvNr,
            occupation,
            employer,
            parent_id: parentId,
            family_status_id: familyStatusId,
            team_id: teamId
          })
          .then(
            async (response) => {
              const { data } = response.data;

              if (name || account || address) {
                await dispatch("addCustomerBank", {
                  customerId: data.id,
                  name,
                  account,
                  address,
                });
              }

              if (file) {
                await dispatch("addCustomerPhoto", {
                  customerId: data.id,
                  file,
                });
              }

              resolve(data);
            },
            (err) => {
              reject(err);
            }
          );
        commit("setCustomersLoading", false);
      }),

    updateCustomer: (
      { commit, dispatch },
      {
        companyName,
        companyType,
        language,
        customerAddresses,
        web,
        uid,
        customer_emails,
        customer_phones,
        name,
        address,
        member_type_id,
        gender,
        titleId,
        firstName,
        lastName,
        birthday,
        countryCode,
        ahvNr,
        occupation,
        employer,
        account,
        companyId,
        bankId,
        file,
        teamId
      }
    ) =>
      new Promise((resolve, reject) => {
        const { street, zip, city, postBox, id, number } = customerAddresses;
        const customer_addresses = [
          { id, street, zip, city, post_box: postBox, number },
        ];
        api({ requiresAuth: true })
          .patch(`/v1/customers/${companyId}`, {
            company_name: companyName,
            company_type_id: companyType,
            language_id: language,
            customer_addresses,
            web,
            uuid: uid,
            customer_emails,
            customer_phones,
            member_type_id,
            gender_id: gender,
            title_id: titleId,
            first_name: firstName,
            last_name: lastName,
            birthday,
            country_code: countryCode,
            ahv_number: ahvNr,
            occupation,
            employer,
            team_id: teamId
          })
          .then(
            async (response) => {
              const { data } = response.data;

              if (!bankId && (name || account)) {
                await dispatch("addCustomerBank", {
                  customerId: data.id,
                  name,
                  account,
                  address,
                });
              } else if (bankId) {
                await dispatch("updateCustomerBank", {
                  bankId,
                  customerId: data.id,
                  name,
                  account,
                  address,
                });
              }

              if (file) {
                await dispatch("addCustomerPhoto", {
                  customerId: data.id,
                  file,
                });
              }

              const { page } = router.currentRoute.query;
              commit("setCustomersLoading", true);
              if (page) {
                await dispatch("searchCustomers", { page });
              }
              resolve(data);
              commit("setCustomersLoading", false);
            },
            (err) => {
              commit("setCustomersLoading", false);
              reject(err);
            }
          );
      }),

    deleteCustomer: async ({ commit, dispatch, getters }, customerId) => {
      commit("setCustomersLoading", true);
      try {
        await api({ requiresAuth: true }).delete(`/v1/customers/${customerId}`);
        await dispatch("searchCustomers", {
          page: getters.currentCustomersPage,
        });
        commit("setCustomersLoading", false);
      } catch (err) {
        console.error(err);
      }
    },
   separateMember: async ({ commit }, customerId) => {
      commit("setCustomersLoading", true);
      try {
        await api({ requiresAuth: true }).post(`/v1/customers/${customerId}/separate`);
      /*  await dispatch("searchCustomers", {
          page: getters.currentCustomersPage,
        });*/
        commit("setCustomersLoading", false);
      } catch (err) {
        console.error(err);
      }
    },
    addMember: async ( { commit },{customerId,  members}) =>  new Promise((resolve, reject) => {
        commit("setCustomersLoading", true);

        api({ requiresAuth: true })
            .post(`/v1/customer-members/${customerId}/add-members`, {
            members: members
        }) .then(
            (response) => {
                const data = response.data.data;
                resolve(data);
            },
            (err) => {
                reject(err);
            }
        );

         }),




    addCustomerPhoto: async (_, { customerId, file }) => {
      const formData = new FormData();
      formData.append("photo", file);
      try {
        await api({ requiresAuth: true }).post(
          `/v1/customers/${customerId}/files`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } catch (err) {
        console.error(err);
      }
    },

    deleteCustomerEmail: (_, emailId) => {
      return new Promise((resolve, reject) => {
        api({ requiresAuth: true })
          .delete(`/v1/customer-emails/${emailId}`)
          .then(
            (response) => {
              const { data } = response;
              resolve(data);
            },
            (err) => {
              console.error(err);
              reject(err);
            }
          );
      });
    },

    deleteCustomerPhone: (_, phoneId) => {
      return new Promise((resolve, reject) => {
        api({ requiresAuth: true })
          .delete(`/v1/customer-phones/${phoneId}`)
          .then(
            (response) => {
              const { data } = response;
              resolve(data);
            },
            (err) => {
              console.error(err);
              reject(err);
            }
          );
      });
    },
  },
};
