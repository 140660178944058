<template>
<div class="customer__form modal modal--default">
    <div class="modal__panel">
        <div class="modal__logo-wrapper">
            <div class="modal__logo"></div>
            <div class="modal__logo-title">The One System</div>
        </div>

        <div class="modal__main-icon modal__main-icon--user"></div>
        <div class="modal__close" @click="closeModal()"></div>

        <div class="form">
            <div class="navigators">
                <div class="navigator navigator--active">
                    <div class="navigator__bullet"></div>
                    <div class="navigator__title"> Taxes </div>
                </div>
            </div>

            <form class="taxes__form"> 
                <transition name="fade" mode="out-in">
                    <Loading v-if="formLoading" />
                    <div v-else class="add__content">
                        <div class="team-info">
                            <div class="team-icon">
                                <img src="../assets/icons/default/team_icon_grey.svg">
                            </div>
                            <p class="team-name">{{teamTaxesToEdit.name}}</p>
                        </div>
 
                        <div class="taxes-list">
                            <div class="form__field taxes-form-field"> 
                                <div v-if="userTaxesList[teamTaxesToEdit.id]" class="team-taxes-wrapper">
                                    <div v-if="userTaxesList[teamTaxesToEdit.id].taxes.length > 0">
                                        <div v-for="tax in userTaxesList[teamTaxesToEdit.id].taxes" :key="tax.id" class="taxes-form-field-wrapper">
                                            <label :for='"tax"+tax.id' class="tax-label">
                                                <span class="tax-name">
                                                    <span :class="{'tax-name-active': selectedTaxes.includes(tax.id)}">{{ tax.name }}</span>
                                                </span>

                                                <span v-if="tax.is_custom == 1" class="tax-value">
                                                    <span class="tax-value-input-wrapper" v-if="selectedTaxes.includes(tax.id)">
                                                        <span class="has-chf" v-if="tax.is_fixed == 1">
                                                            <input type="text" class="input input--default tax-value-input" v-model="selectedTaxesValue[tax.id]" />
                                                        </span>

                                                        <span class="has-precentage" v-else>
                                                            <input type="text" class="input input--default tax-value-input" v-model="selectedTaxesValue[tax.id]" />
                                                        </span>
                                                    </span>
                                                    <span v-if="tax.is_fixed == 1 && !selectedTaxes.includes(tax.id)">
                                                        {{ (tax.rate * 100).toFixed(2) }} .-CHF
                                                    </span>
                                                    <span v-if="tax.is_fixed == 0 && !selectedTaxes.includes(tax.id)">
                                                        {{ (tax.rate * 100).toFixed(2) }} %
                                                    </span>
                                                </span>

                                                <span v-else class="tax-value">
                                                    {{ (tax.rate * 100).toFixed(2) }} .-CHF
                                                </span>
                                            </label>
                                            <div class="checkbox">
                                                <input type="checkbox" :id='"tax"+tax.id' class="checkbox__checkbox" :value="tax.id" v-model="selectedTaxes" />
                                                <label :for='"tax"+tax.id' class="checkbox__label"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <p class="tax-name">
                                            No Taxes Found.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div 
                            v-if="isRequesting" 
                            class="modal__button modal__button--submit button button--default button--loading"
                        >
                        </div>
                        <button 
                            v-else 
                            type="button" 
                            class="modal__button modal__button--submit button button--default" 
                            @click="updateUserTaxes()"
                        >
                            {{ $t(`addUserModal.${"saveBtn"}`) }}  
                        </button>
                    </div>
                </transition>
            </form> 
        </div> 
    </div>
</div>
</template>

<script>
import api from "@/api";
import {
    mapGetters
} from "vuex";
 import Loading from "@/partials/Loading";
export default {
    components:{
        Loading
    },
    data() {
        return {
            formLoading: false,
            userTeams: [],

            userTaxesList: {},
            selectedTaxes: [],
            selectedTaxesValue: {},

            taxes: [],

            isRequesting: false,
        }
    },
    computed: {
        ...mapGetters(["userForms", "isEdit", "userToEdit", "isProfileEdit", "loggedUser", "teamTaxesToEdit"]),

        teams() {
            return this.userForms.teams;
        },
    },
    methods: {
        
        async updateUserTaxes() {
            this.isRequesting = true;

            try { 
                const taxes = this.taxes;
                const userId = this.userToEdit;

                if (!this.isEdit || !userId) {
                    throw new Error("Invalid edit mode or user ID");
                }
 
                await api({ requiresAuth: true }).patch(`/v1/users/${userId}/taxes`, { taxes });
 
                this.$store.commit("setToast", {
                    display: true,
                    type: "success",
                    message: "Taxes updated successfully.",
                });
                this.closeModal();
            } catch (err) { 
                console.error(err);  

                this.$store.commit("setToast", {
                    display: true,
                    type: "error",
                    message: "Failed to update taxes. Please try again: " + err,
                });
            } finally { 
                this.isRequesting = false;
            }
        },



        closeModal() {
            this.$store.commit("setShowTaxesModal", false);
            this.$store.commit("setIsEdit", false);
            this.$store.commit("setIsProfileEdit", false);
        },

        async getUserTaxes(teamId) {
            try {
                const response = await api({
                    requiresAuth: true
                }).get(`/v1/team-taxes/${teamId}`);
                const taxes = response.data.data;

                if (!this.userTaxesList) {
                    this.userTaxesList = {};
                }

                this.$set(this.userTaxesList, teamId, {
                      taxes
                });

            } catch (error) {
                console.error(`Failed to fetch taxes for team ID ${teamId}:`, error);
            }
        },

        loadFieldsForEdit(userToEdit) { 
            const {
                teams,
                taxes,
            } = userToEdit; 

            this.userTeams = teams.map((team) => team.id) || "";

            if (teams && teams.length > 0) {
                this.selectedTaxes = [];
                this.selectedTaxesValue = {};

                this.userTaxesList = {};

                teams.forEach((team) => {
                    const teamId = team.id; 
                    this.getUserTaxes(teamId);
                });

                taxes.forEach((tax) => {
                    if (!this.selectedTaxes.includes(tax.id)) {
                        this.selectedTaxes.push(tax.id);
                        this.$set(this.selectedTaxesValue, tax.id, tax.value);
                    }
                });
            } else {
                this.selectedTaxes = [];
                this.selectedTaxesValue = {};
            }

            this.updateTaxes();
        },

        updateTaxes() {
            this.taxes = this.selectedTaxes.map(taxId => ({
                team_tax_id: taxId,
                value: this.selectedTaxesValue[taxId] || ''
            }));
        },
    },
    watch: {
        selectedTaxes(newSelectedTaxes, oldSelectedTaxes) { 
            newSelectedTaxes.forEach(taxId => {
                if (!this.selectedTaxesValue[taxId]) {
                    this.$set(this.selectedTaxesValue, taxId, '');
                }
            });
 
            oldSelectedTaxes.forEach(taxId => {
                if (!newSelectedTaxes.includes(taxId)) {
                    this.$delete(this.selectedTaxesValue, taxId);
                }
            });

            this.updateTaxes();
        },
        selectedTaxesValue: {
            handler() {
                this.updateTaxes();
            },
            deep: true
        }
    },
    async mounted() { 
        this.formLoading = true;

        await this.$store.dispatch("getUserForms");

        if (this.isEdit || this.isProfileEdit) {
            let user;

            user = await this.$store.dispatch("getUser", this.userToEdit);

            this.editingUser = user;
            this.loadFieldsForEdit(user); 
        }

        this.formLoading = false;
    },
}
</script>

<style lang="scss" scoped>
.modal__button{
    position: relative;
    top: 0;
    left: 0;
    margin: 20px auto; 
}

.taxes__form {
    height: calc(100% - 16px - 40px);
    width: 60%;
    margin: 0 auto;
}

.team-info {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
}

.team-icon {
    width: 22px;
    height: 22px;
    margin-top: 5px;

    img {
        width: 100%;
    }
}

.team-name {
    color: #B9C2CD;
    font-weight: 500;
    font-size: 16px;
}

.taxes-list {
    padding-top: 10px;
    border: 1px solid transparent;
    border-top-color: #B9C2CD;
}

.taxes-form-field {
    margin-top: 20px;
}

.taxes-form-field-wrapper {
    margin-bottom: 30px;
    display: flex; 
    gap: 10px;
    align-items: center;
}

.taxes-form-field-wrapper:hover .tax-label .tax-name,
.tax-name-active {
  color: var(--text-5);
}

.tax-label {
    display: flex;
    justify-content: space-between; 
    font-family: var(--muli-text-semi-bold);
    font-size: 16px;
    cursor: pointer;
    flex: 1;
}

.tax-name { 
    color: var(--bg-4);
}

[data-theme="dark"] {
    .tax-name { 
        color: white;
    }
}

.tax-name,
.tax-value {
    width: 50%;
    word-break: break-word;
    display: flex;
    align-items: center;
    font-family: var(--muli-text-semi-bold);
}

.tax-value {
    text-align: right;
    color: var(--text-5);
    justify-content: flex-end;
}

.tax-value-input-wrapper {
    width: 100%;
    position: relative;
}

.tax-value-input {
    width: 80% !important;
    height: 32px !important;
    position: relative;
}

.tax-value-input::placeholder {
    text-align: right;
}

.has-chf::after,
.has-precentage::after {
    content: ".-CHF";
    position: absolute;
    right: 10px;
    top: 50%;
    font-size: 14px;
    transform: translateY(-50%);
    pointer-events: none;
    color: var(--text-2);
}

.has-precentage::after {
    content: "%";
}
</style>
