<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>

      <div class="modal__main-icon modal__main-icon--private"></div>
      <div class="modal__back" @click="goBack()"></div>
      <div class="modal__close" @click="closeModal()"></div>

      <div class="form">
        <div class="navigators">
          <div
            class="navigator"
            @click="goTo(0)"
            :class="{
              'navigator--active':
                currentPage === 0 || currentPage === 1 || currentPage === 2,
            }"
          >
            <div class="navigator__bullet"></div>
            <div class="navigator__title">{{ $t(`addPrivateCostumerModal.${"details"}`) }}</div>
          </div>
          <div
            class="navigator"
            @click="goTo(1)"
            :class="{
              'navigator--active': currentPage === 1 || currentPage === 2,
            }"
          >
            <div class="navigator__bullet"></div>
            <div class="navigator__title">{{ $t(`addPrivateCostumerModal.${"contact"}`) }}</div>
          </div>
          <div
            class="navigator"
            @click="goTo(2)"
            :class="{ 'navigator--active': currentPage === 2 }"
          >
            <div class="navigator__bullet"></div>
            <div class="navigator__title">{{ $t(`addPrivateCostumerModal.${"image"}`) }}</div>
          </div>
        </div>

        <form class="add__form">
          <transition name="fade" mode="out-in">
            <Loading v-if="formLoading" />
            <div v-else class="add__content">
              <transition name="fade" mode="out-in">
                <div class="details" key="0" v-if="currentPage === 0">
                  <div class="form__row">
                    <div class="form__field form__field--double">
                      <VSelect
                        :options="genders"
                        :reduce="(gender) => gender.id"
                        placeholder="Anrede *"
                        label="name"
                        v-model="privateDetails.gender"
                        class="select select--default"
                        :class="{ 'select--error': genderHasError }"
                      />
                      <div class="form__error" v-if="genderHasError">
                        {{ $t(`addPrivateCostumerModal.${"genderErrorMsg"}`) }}
                      </div>
                    </div>
                    <div class="form__field form__field--double">
                      <VSelect
                        :options="titles"
                        :reduce="(title) => title.id"
                        placeholder="Titel"
                        label="name"
                        v-model="privateDetails.titleId"
                        class="select select--default"
                      />
                    </div>
                  </div>

                  <div class="form__field" v-if="isAddingMember">
                    <VSelect
                      class="select select--default"
                      :options="familyStatuses"
                      :reduce="(status) => status.id"
                      label="name"
                      placeholder="Relation"
                      v-model="privateDetails.familyStatusId"
                    />
                  </div>

                  <div class="form__field">
                    <input
                      type="text"
                      class="input input--default"
                      placeholder="Vorname *"
                      v-model="privateDetails.firstName"
                      :class="{
                        'input--error': firstNameHasError,
                      }"
                    />
                    <div class="form__error" v-if="firstNameHasError">
                      {{ firstNameErrorPlaceholder }}
                    </div>
                  </div>

                  <div class="form__field">
                    <input
                      type="text"
                      class="input input--default"
                      placeholder="Name *"
                      v-model="privateDetails.lastName"
                      :class="{ 'input--error': lastNameHasError }"
                    />
                    <div class="form__error" v-if="lastNameHasError">
                      {{ lastNameErrorPlaceholder }}
                    </div>
                  </div>

                  <div class="form__row">
                    <div class="form__field form__field--double">
                      <Datepicker
                        class="input input--default"
                        :class="{
                          'input--error': birthdayHasError,
                        }"
                        :config="statics.datePickerConfig"
                        placeholder="Geburtstag *"
                        v-model="privateDetails.birthday"
                      />
                      <div class="form__error" v-if="birthdayHasError">
                        {{ $t(`addPrivateCostumerModal.${"birthdateErrorMsg"}`) }}
                      </div>
                    </div>
                    <div class="form__field form__field--double">
                      <VSelect
                        :options="languages"
                        :reduce="(language) => language.id"
                        placeholder="Sprache *"
                        label="code"
                        v-model="privateDetails.language"
                        class="select select--default"
                        :class="{ 'select--error': languageHasError }"
                      />
                      <div class="form__error" v-if="languageHasError">
                        {{ $t(`addPrivateCostumerModal.${"languageErrorMsg"}`) }}
                      </div>
                    </div>
                  </div>

                  <div class="form__row">
                    <div class="form__field form__field--double">
                      <input
                        type="text"
                        class="input input--default"
                        placeholder="Strasse *"
                        v-model="privateAddress[0].street"
                        :class="{
                          'input--error': streetHasError,
                        }"
                      />
                      <div class="form__error" v-if="streetHasError">
                        {{ $t(`addPrivateCostumerModal.${"streetErrorMsg"}`) }}
                      </div>
                    </div>
                    <div class="form__field">
                      <input
                        type="text"
                        class="input input--default input--nr"
                        placeholder="Nr."
                        v-model="privateAddress[0].number"
                      />
                    </div>
                  </div>

                  <div class="form__row">
                    <div class="form__field form__field--double">
                      <input
                        type="text"
                        class="input input--default"
                        placeholder="PLZ *"
                        v-model="privateAddress[0].zip"
                        :class="{ 'input--error': zipHasError }"
                      />

                      <div class="form__error" v-if="zipHasError">
                        {{ zipCodePlaceholder }}
                      </div>
                    </div>
                    <div class="form__field form__field--double">
                      <VSelect
                        v-if="zipCodeData.length > 1"
                        :options="zipCodeData"
                        :reduce="(city) => city.city"
                        placeholder="ORT *"
                        label="city"
                        v-model="privateAddress[0].city"
                        class="select select--default"
                        :class="{
                          'select--error': cityHasError,
                        }"
                      />

                      <div
                        v-else-if="zipCodeData.length"
                        class="input input--default input--readonly"
                        :class="{ 'input--error': cityHasError }"
                      >
                        {{ privateAddress[0].city }}
                      </div>

                      <input
                        v-else-if="!zipCodeData.length"
                        type="text"
                        class="input input--default"
                        placeholder="ORT *"
                        v-model="privateAddress[0].city"
                        :class="{ 'input--error': cityHasError }"
                      />

                      <div class="form__error" v-if="cityHasError"> 
                        {{ $t(`addPrivateCostumerModal.${"cityErrorMsg"}`) }}
                      </div>
                    </div>
                  </div>
                  <div class="form__field" v-if="isAddingMember">
                    <div class="input input--default input--readonly">
                      {{ customer.team.name }}
                    </div> 
                  </div>
                  <div class="form__field" v-else> 
                    <VSelect
                        :options="teams"
                        :reduce="(team) => team.id"
                        placeholder="Teams *"
                        label="name"
                        v-model="privateDetails.teamId"
                        class="select select--default"
                        :class="{'select--error': teamHasError}"
                    />
                    <div class="form__error" v-if="teamHasError"> 
                      {{ $t(`addPrivateCostumerModal.${"teamErrorMsg"}`) }}
                    </div>
                  </div>
                </div>


                <div class="contact" key="1" v-if="currentPage === 1">
                  <div class="form__field">
                    <div
                      class="form__input-wrapper"
                      v-for="(email, index) in customerEmails"
                      :key="index"
                    >
                      <input
                        type="text"
                        class="input input--default"
                        :placeholder="isAddingMember ? 'E-mail' : 'E-mail'"
                        @input="validateEmail($event, index)"
                        :value="email.email"
                        :class="{
                          'input--error': email.hasError,
                        }"
                      />
                      <div
                        class="form__add"
                        v-if="index === 0 && email.email"
                        @click="addInput('email')"
                      ></div>
                      <div
                        class="form__remove"
                        v-else-if="index !== 0"
                        @click="removeInput('email', email.id, index)"
                      ></div>
                      <div class="form__error" v-if="email.hasError">
                        {{ email.errorPlaceholder }}
                      </div>
                    </div>
                  </div>
                  <div class="form__field form__field--prefix">
                    <div
                      class="form__row"
                      v-for="(phone, index) in customerPhones"
                      :key="index"
                    >
<!--                      <div
                        class="form__input-wrapper form__input-wrapper&#45;&#45;prefix"
                      >
                        <VSelect
                          :options="phonePrefixes"
                          class="select select&#45;&#45;default"
                          :class="{
                            'input&#45;&#45;error': phone.prefixHasError,
                          }"
                          placeholder="Prefix"
                          @input="validatePrefix($event, index)"
                          :value="phone.prefix"
                        />
                        <div class="form__error" v-if="phone.prefixHasError">
                          {{ phone.prefixErrorPlaceholder }}
                        </div>
                      </div>-->
                      <div class="form__input-wrapper">
                        <input
                          type="text"
                          class="input input--default"
                          :placeholder="
                            isAddingMember ? 'Telefon' : 'Telefon'
                          "
                          @input="validatePhone($event, index)"
                          :value="phone.phone"
                          :class="{
                            'input--error': phone.hasError,
                          }"
                        />
                        <div
                          class="form__add"
                          v-if="index === 0 && phone.phone"
                          @click="addInput('phone')"
                        ></div>
                        <div
                          class="form__remove"
                          v-else-if="index !== 0"
                          @click="removeInput('phone', phone.id, index)"
                        ></div>
                        <div class="form__error" v-if="phone.hasError">
                          {{ phone.errorPlaceholder }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form__field">
                    <VSelect
                      :options="countries"
                      :reduce="(country) => country.code"
                      placeholder="Nationalitat"
                      label="name_en"
                      v-model="privateContact.countryCode"
                      class="select select--default"
                    />
                  </div>
                  <div class="form__field">
                    <input
                      type="text"
                      class="input input--default"
                      placeholder="Beruf"
                      v-model="privateContact.occupation"
                    />
                  </div>
                  <div class="form__field">
                    <input
                      type="text"
                      class="input input--default"
                      placeholder="Arbeitgeber"
                      v-model="privateContact.employer"
                    />
                  </div>
                  <div class="form__field">
                    <input
                      type="text"
                      class="input input--default"
                      placeholder="AHV Nr."
                      v-model="privateContact.ahvNr"
                    />
                  </div>
                  <div class="form__field">
                    <input
                      type="text"
                      class="input input--default"
                      placeholder="IBAN"
                      v-model="privateBank.account"
                    />
                  </div>
                  <div class="form__field">
                    <input
                      type="text"
                      class="input input--default"
                      placeholder="Bankname"
                      v-model="privateBank.name"
                    />
                  </div>
                </div>

                <div class="logo" key="2" v-if="currentPage === 2">
                  <div class="add__avatar">
                    <img
                      v-if="userImage"
                      :src="userImage"
                      alt=""
                      ref="userImage"
                    />
                    <div v-else class="add__avatar-empty">
                      <img
                        class="add__avatar-empty"
                        src="../assets/icons/default/Bold 2px-person-white.svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="add__upload-text">
                    {{ $t(`addPrivateCostumerModal.${"uploadText"}`) }}
                  </div>
                  <div class="add__upload-wrapper">
                    <button
                      type="button"
                      class="button button--default button--upload"
                      @click="$refs.uploadFile.click()"
                    >
                    {{ $t(`addPrivateCostumerModal.${"uploadBtn"}`) }}
                    </button>
                    <input
                      ref="uploadFile"
                      type="file"
                      class="add__upload"
                      @change="uploadImage($event)"
                    />
                  </div>
                </div>
              </transition>
            </div>
          </transition>
        </form>
      </div>
      <div
        v-if="isRequesting"
        class="modal__button modal__button--submit button button--default button--loading"
      ></div>
      <button
        v-else
        type="button"
        class="modal__button modal__button--submit button button--default"
        @click="currentPage !== 2 ? goForward() : createCustomer()"
      >
        <span v-if="currentPage === 2">{{ $t(`addPrivateCostumerModal.${"save"}`) }}</span>
        <span v-else>{{ $t(`addPrivateCostumerModal.${"next"}`) }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import Loading from "@/partials/Loading";
import statics from "@/statics";
import parseFieldErrors from "@/helpers/parseFieldErrors";

export default {
  name: "AddPrivateModal",

  components: {
    Loading,
  },

  data() {
    return {
      statics,
      parseFieldErrors,
      currentPage: 0,
      formLoading: false,
      isRequesting: false,
      userImage: "",
      emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      editingCustomer: "",
      image: "",
      file: "",
      showTeams: false,

      privateDetails: {
        gender: "",
        titleId: "",
        firstName: "",
        lastName: "",
        birthday: moment(new Date()).format("YYYY-MM-DD"),
        language: "",
        familyStatusId: "",
        teamId: "",
      },

      privateAddress: [
        {
          street: "",
          number: "",
          city: "",
          zip: "",
        },
      ],

      privateContact: {
        countryCode: "",
        ahvNr: "",
        occupation: "",
        employer: "",
      },

      privateBank: {
        name: "",
        account: "",
      },

      customerEmails: [
        {
          email: "",
          hasError: false,
          errorPlaceholder: "",
        },
      ],

      customerPhones: [
        {
          prefix: "",
          phone: "",
          hasError: false,
          errorPlaceholder: "",
        },
      ],

      firstNameHasError: false,
      lastNameHasError: false,
      genderHasError: false,
      birthdayHasError: false,
      languageHasError: false,
      zipHasError: false,
      cityHasError: false,
      emailHasError: false,
      phoneHasError: false,
      streetHasError: false,
      teamHasError: false,

      firstNameErrorPlaceholder: "",
      lastNameErrorPlaceholder: "",

      specificErrors: [],
    };
  },

  watch: {
    "privateDetails.teamId"(value) {
      if (value) {
        this.teamHasError = false;
      } else {
        this.teamHasError = true;
      }
    },
    "privateDetails.gender"(value) {
      if (value) {
        this.genderHasError = false;
      } else {
        this.genderHasError = true;
      }
    },
    "privateDetails.firstName"(value) {
      if (value) {

          this.firstNameHasError = false;
          this.firstNameErrorPlaceholder = "";

      } else {
        this.firstNameErrorPlaceholder = "Please enter first name";
        this.firstNameHasError = true;
      }
    },
    "privateDetails.lastName"(value) {
      if (value) {

          this.lastNameHasError = false;
          this.lastNameErrorPlaceholder = "";

      } else {
        this.lastNameErrorPlaceholder = "Please enter last name";
        this.lastNameHasError = true;
      }
    },
    "privateDetails.birthday"(value) {
      if (value) {
        this.birthdayHasError = false;
      } else {
        this.birthdayHasError = true;
      }
    },
    "privateDetails.language"(value) {
      if (value) {
        this.languageHasError = false;
      } else {
        this.languageHasError = true;
      }
    },
    async zip(zip) {
      if (zip) {
        this.zipHasError = false;
        this.zipCodePlaceholder = "";

        if (Number(zip) >= 1000 && Number(zip) <= 9999) {
          await this.loadCities(zip);
        } else {
          this.zipCodeData = [];
          this.privateAddress[0].city = "";
          this.zipHasError = true;
          this.zipCodePlaceholder = "Invalid zip code";
        }
      } else {
        this.privateAddress[0].city = "";
        this.zipHasError = true;
        this.zipCodePlaceholder = "Please enter a zip code";
      }
    },
    city(value) {
      if (value) {
        this.cityHasError = false;
      } else {
        this.cityHasError = true;
      }
    },
    street(value) {
      if (value) {
        this.streetHasError = false;
      } else {
        this.streetHasError = true;
      }
    },

    specificErrors(errors) {
      const errorKeys = errors.map((error) => error.errorKey);

      if (errorKeys.includes("email")) {
        this.currentPage = 1;
        errors.forEach((error) => {
          if (error.errorKey === "email") {
            this.customerEmails[error.errorPos].errorPlaceholder =
              error.errorValue;
            this.customerEmails[error.errorPos].hasError = true;
          }
        });
      } else {
        this.customerEmails.forEach((email) => {
          email.errorPlaceholder = "";
          email.hasError = false;
        });
      }

      if (errorKeys.includes("phone")) {
        this.currentPage = 1;
        errors.forEach((error) => {
          if (error.errorKey === "phone") {
            this.customerPhones[error.errorPos].errorPlaceholder =
              error.errorValue;
            this.customerPhones[error.errorPos].hasError = true;
          }
        });
      } else {
        this.customerPhones.forEach((phone) => {
          phone.errorPlaceholder = "";
          phone.hasError = false;
        });
      }

      if (errorKeys.includes("first_name")) {
        this.currentPage = 0;
        this.firstNameErrorPlaceholder = errors.find(
          (error) => error.errorKey === "first_name"
        ).errorValue;
        this.firstNameHasError = true;
      } else {
        this.firstNameHasError = false;
        this.firstNameErrorPlaceholder = "";
      }

      if (errorKeys.includes("last_name")) {
        this.currentPage = 0;
        this.lastNameErrorPlaceholder = errors.find(
          (error) => error.errorKey === "last_name"
        ).errorValue;
        this.lastNameHasError = true;
      } else {
        this.lastNameHasError = false;
        this.lastNameErrorPlaceholder = "";
      }
    },
  },

  async mounted() {
    this.formLoading = true;

    await this.$store.dispatch("getCustomerForms");

    if (this.privateIsEdit) {
      const customer = await this.$store.dispatch(
        "getCustomer",
        this.privateToEdit
      );
      this.editingCustomer = customer;
      this.loadFieldsForEdit(customer);
    }

    if (this.isAddingMember) {
      this.privateDetails.lastName = this.customer.last_name;
      this.privateAddress[0].id = this.customer.customer_addresses.length
        ? this.customer.customer_addresses[0].id
        : "";
      this.privateAddress[0].street = this.customer.customer_addresses.length
        ? this.customer.customer_addresses[0].street
        : "";
      this.privateAddress[0].postBox = this.customer.customer_addresses.length
        ? this.customer.customer_addresses[0].post_box
        : "";
      this.privateAddress[0].zip = this.customer.customer_addresses.length
        ? this.customer.customer_addresses[0].zip
        : "";
      this.privateAddress[0].number = this.customer.customer_addresses.length
        ? this.customer.customer_addresses[0].number
        : "";
    }
    this.formLoading = false;
    if(this.customerForms.teams.length > 0)
      this.showTeams = (this.loggedUser.is_super_admin && !this.editingCustomer);
  },

  computed: {
    ...mapGetters([
      "customerForms",
      "privateIsEdit",
      "privateToEdit",
      "isAddingMember",
      "customer",
      "isAddingPotentialCustomer",
      "language",
      "loggedUser",
    ]),

    familyStatuses() {
      return this.customerForms.family_statuses;
    },

    genders() {
      return this.customerForms.genders;
    },

    titles() {
      return this.customerForms.titles;
    },

    languages() {
      return this.customerForms.languages;
    },
    teams() {
      return this.customerForms.teams;
    },
    countries() {
      return this.customerForms.countries;
    },

    phonePrefixes() {
      return this.customerForms.prefixes.map((prefix) => prefix.phone_prefix);
    },

    zipCodeData: {
      get() {
        return this.$store.state.zipCodes.zipCodeData;
      },
      set(value) {
        this.$store.commit("setZipCodeData", value);
      },
    },

    zip() {
      return this.privateAddress[0].zip;
    },
    city() {
      return this.privateAddress[0].city;
    },
    street() {
      return this.privateAddress[0].street;
    },
  },

  methods: {
    closeModal() {
      this.$store.commit("setShowAddPrivateModal", false);
      this.$store.commit("setPrivateIsEdit", false);
      this.$store.commit("setIsAddingMember", false);
      this.$store.commit("setIsAddingPotentialCustomer", false);
      this.zipCodeData = [];
    },

    loadFieldsForEdit(customer) {
      this.privateDetails.gender = customer.gender ? customer.gender.id : "";
      this.privateDetails.teamId = customer.team ? customer.team.id : "";
      this.privateDetails.titleId = customer.title ? customer.title.id : "";
      this.privateDetails.birthday = customer.birthday || "";
      this.privateDetails.firstName = customer.first_name || "";
      this.privateDetails.lastName = customer.last_name || "";
      this.privateDetails.language = customer.language.id || "";
      this.privateAddress[0].id = customer.customer_addresses.length
        ? customer.customer_addresses[0].id
        : "";
      this.privateAddress[0].street = customer.customer_addresses.length
        ? customer.customer_addresses[0].street
        : "";
      this.privateAddress[0].postBox = customer.customer_addresses.length
        ? customer.customer_addresses[0].post_box
        : "";
      this.privateAddress[0].zip = customer.customer_addresses.length
        ? customer.customer_addresses[0].zip
        : "";
      this.privateAddress[0].number = customer.customer_addresses.length
        ? customer.customer_addresses[0].number
        : "";
      this.privateAddress[0].city = customer.customer_addresses.length
        ? customer.customer_addresses[0].city
        : "";
      this.customerEmails = customer.customer_emails.length
        ? customer.customer_emails.map((email) => ({
            id: email.id,
            email: email.email,
            hasError: false,
            errorPlaceholder: "",
          }))
        : [
            {
              id: "",
              email: "",
              hasError: false,
              errorPlaceholder: "",
            },
          ];

      this.customerPhones = customer.customer_phones.length
        ? customer.customer_phones.map((phone) => ({
            id: phone.id,
            prefix: phone.prefix,
            phone: phone.phone,
            hasError: false,
            errorPlaceholder: "",
          }))
        : [
            {
              id: "",
              prefix: "",
              phone: "",
              hasError: "",
              errorPlaceholder: "",
              prefixHasError: "",
              prefixErrorPlaceholder: "",
            },
          ];
      this.privateContact.countryCode = customer.country
        ? customer.country.code
        : "";
      this.privateContact.occupation = customer.occupation || "";
      this.privateContact.employer = customer.employer || "";
      this.privateContact.ahvNr = customer.ahv_number || "";
      this.userImage = customer.photo ? customer.photo.preview : "";
      this.privateBank.bankId = customer.banks.length
        ? customer.banks[0].id
        : "";
      this.privateBank.account = customer.banks.length
        ? customer.banks[0].account
        : "";
      this.privateBank.name = customer.banks.length
        ? customer.banks[0].name
        : "";
      this.privateBank.address = customer.banks.length
        ? customer.banks[0].address
        : "";
    },

    goBack() {
      this.currentPage--;
      if (this.currentPage < 0) {
        this.$store.commit("setShowAddPrivateModal", false);
        this.$store.commit("setPrivateIsEdit", false);
        this.$store.commit("setShowAddCustomerModal", true);
      }
    },

    goTo(page) {
      if (page === 1) {
        this.validateCustomerDetails((state) => {
          if (state) {
            this.currentPage = page;
          }
        });
      } else if (page === 2) {
        this.validateCustomerDetails((detailsState) => {
          this.validateCustomerContact((contactState) => {
            if (contactState && detailsState) {
              this.currentPage = page;
            }
          });
        });
      } else {
        this.currentPage = page;
      }
    },

    goForward() {
      if (this.currentPage < 2) {
        if (this.currentPage === 0) {
          this.validateCustomerDetails((state) => {
            if (state) {
              this.currentPage++;
            }
          });
        } else if (this.currentPage === 1) {
          this.validateCustomerContact((state) => {
            if (state) {
              this.currentPage++;
            }
          });
        }
      }
    },

    validateCustomerDetails(callback) {
      const {
        firstName,
        lastName,
        language,
        gender,
        birthday,
        teamId
      } = this.privateDetails;
      const { zip, city, street } = this.privateAddress[0];
      if (!firstName) {
        this.firstNameHasError = true;
        this.firstNameErrorPlaceholder = "Please enter first name";
      } else {
          this.firstNameHasError = false;
          this.firstNameErrorPlaceholder = "";

      }

      if (!lastName) {
        this.lastNameHasError = true;
        this.lastNameErrorPlaceholder = "Please enter last name";
      } else {
        this.lastNameHasError = false;
        this.lastNameErrorPlaceholder = "";
      }

      if (!teamId) {
        this.teamHasError = true;
      }
      if (!gender) {
        this.genderHasError = true;
      }
      if (!language) {
        this.languageHasError = true;
      }
      if (!birthday) {
        this.birthdayHasError = true;
      }
      if (!zip) {
        this.zipHasError = true;
        this.zipCodePlaceholder = "Please enter a zip code";
      }
      if (!city) {
        this.cityHasError = true;
      }
      if (!street) {
        this.streetHasError = true;
      }

      if (
        !this.firstNameHasError &&
        !this.lastNameHasError &&
        !this.languageHasError &&
        !this.genderHasError &&
        !this.birthdayHasError &&
        !this.zipHasError &&
        !this.streetHasError &&
        !this.cityHasError
      ) {
        callback(true);
      }
    },

    validateCustomerContact(callback) {
      if (!this.isAddingMember) {
        // this.customerEmails.forEach((email) => {
        //   if (!email.email) {
        //     email.hasError = true;
        //     email.errorPlaceholder = "Email address field is required";
        //   }
        // });
     /*   this.customerPhones.forEach((phone) => {

          if (!phone.phone) {
            phone.hasError = true;
            phone.errorPlaceholder = "Phone field is required";
          }
        });*/
      }
      this.customerEmails = [...this.customerEmails];
      this.customerPhones = [...this.customerPhones];
      if (
        !this.customerEmails.some((email) => email.hasError) &&
        !this.customerPhones.some(
          (phone) => phone.hasError || phone.prefixHasError
        )
      ) {
        callback(true);
      }
    },

    validateEmail(event, index) {
      const { value } = event.target;
      this.customerEmails[index].email = value;
      if (!this.isAddingMember) {
        if (this.customerEmails[index].email) {
          this.customerEmails[index].hasError = false;
          this.customerEmails[index].errorPlaceholder = "";

          if (
            !this.emailRegex.test(
              this.customerEmails[index].email.toLowerCase()
            )
          ) {
            this.customerEmails[index].hasError = true;
            this.customerEmails[index].errorPlaceholder =
              "Invalid email address.";
          }
        } /*else {
          this.customerEmails[index].hasError = true;
          this.customerEmails[index].errorPlaceholder =
            "Email address field is required.";
        }*/
      } else {
        if (
          this.customerEmails[index].email &&
          !this.emailRegex.test(this.customerEmails[index].email.toLowerCase())
        ) {
          this.customerEmails[index].hasError = true;
          this.customerEmails[index].errorPlaceholder =
            "Invalid email address.";
        } else {
          this.customerEmails[index].hasError = false;
          this.customerEmails[index].errorPlaceholder = "";
        }
      }

      this.customerEmails = [...this.customerEmails];
    },

    validatePrefix(value, index) {
      this.customerPhones[index].prefix = value;
      console.log(value);
      if (!this.customerPhones[index].prefix) {
        this.customerPhones[index].prefixHasError = true;
        this.customerPhones[index].prefixErrorPlaceholder = "Invalid prefix";
      } else {
        this.customerPhones[index].prefixHasError = false;
        this.customerPhones[index].prefixErrorPlaceholder = "";
      }
    },

    validatePhone(event, index) {
      const { value } = event.target;
      this.customerPhones[index].phone = value;
      if (!this.isAddingMember) {
        if (this.customerPhones[index].phone) {
          if (!this.statics.phoneRegex.test(value)) {
            this.customerPhones[index].hasError = true;
            this.customerPhones[index].errorPlaceholder =
              "Invalid phone number.";
          } else {
            this.customerPhones[index].hasError = false;
            this.customerPhones[index].errorPlaceholder = "";
          }
        } /*else {
          this.customerPhones[index].hasError = true;
          this.customerPhones[index].errorPlaceholder =
            "Phone field is required.";
        }*/
      } else {
        if (
          this.customerPhones[index].phone &&
          !this.statics.phoneRegex.test(value)
        ) {
          this.customerPhones[index].hasError = true;
          this.customerPhones[index].errorPlaceholder = "Invalid phone number.";
        } else {
          this.customerPhones[index].hasError = false;
          this.customerPhones[index].errorPlaceholder = "";
        }
      }

      this.customerPhones = [...this.customerPhones];
    },

    addInput(type) {
      if (type === "email") {
        this.customerEmails = [...this.customerEmails, { email: "" }];
      } else if (type === "phone") {
        this.customerPhones = [
          ...this.customerPhones,
          { phone: "", prefix: "" },
        ];
      }
    },

    async removeInput(type, id, index) {
      if (type === "email") {
        if (!id) {
          this.customerEmails.splice(index, 1);
        } else {
          try {
            await this.$store.dispatch("deleteCustomerEmail", id);
            this.customerEmails.splice(
              this.customerEmails.findIndex((email) => email.id === id),
              1
            );
          } catch (err) {
            console.error(err);
          }
        }
      } else if (type === "phone") {
        if (!id) {
          this.customerPhones.splice(index, 1);
        } else {
          try {
            await this.$store.dispatch("deleteCustomerPhone", id);
            this.customerPhones.splice(
              this.customerPhones.findIndex((phone) => phone.id === id),
              1
            );
          } catch (err) {
            console.error(err);
          }
        }
      }
    },

    uploadImage(event) {
      this.file = event.target.files[0];
      this.userImage = URL.createObjectURL(this.file);
    },

    async loadCities(value) {
      await this.$store.dispatch("getZipCodeData", value);
      this.privateAddress[0].zip = value;
      this.privateAddress[0].city = this.zipCodeData[0].city;
    },

    async fetchEntity() {  
      const {   entity } = this.$route.params;
      if (entity === "customer" || entity === "potential-customer") {
 
         this.$store.commit("setCustomerDetails", this.customer);
      } 
    },

    async createCustomer() {
      this.isRequesting = true;
      if (this.privateIsEdit) {
        this.image = this.editingCustomer.photo
          ? this.editingCustomer.photo.file_name
          : "";
      }

      const customer_emails = this.customerEmails[0].email
        ? this.customerEmails.map((email) => ({
            id: email.id,
            email: email.email,
          }))
        : [];
      const customer_phones = this.customerPhones[0].phone
        ? this.customerPhones.map((phone) => ({
            id: phone.id,
            phone: phone.phone,
            prefix: phone.prefix,
          }))
        : [];

      const customer = {
        ...this.privateDetails,
        ...this.privateContact,
        ...this.privateBank,
        file: this.file,
        member_type_id: this.statics.memberTypes.private,
        customerAddresses: this.privateAddress[0],
        customer_emails,
        customer_phones,
      };
      try {
        if (this.privateIsEdit) {
          const { id } = await this.$store.dispatch("updateCustomer", {
            ...customer,
            companyId: this.privateToEdit,
          });
          if (this.$route.name === "AccountDetails") {
            
            await this.$store.dispatch("getGlobalCustomer", id);
            //this.fetchEntity();
            this.$store.commit("setCustomerDetails", this.customer);
          }
          this.$store.commit("setToast", {
            display: true,
            type: "success",
            message: "Customer edited successfully.",
          });
        } else {
          if (this.isAddingMember) {
            const { parent_id } = this.customer;
            const { id } = this.$route.params;
            await this.$store.dispatch("addCustomer", {
              ...customer,
              parentId: parent_id || id,
              teamId: this.customer.team.id
            });
            await this.$store.dispatch("getGlobalCustomer", id);
            this.$store.commit("setToast", {
              display: true,
              type: "success",
              message: "Member added successfully.",
            });
          } else {
            const { id } = await this.$store.dispatch("addCustomer", customer);
            this.$store.commit("setToast", {
              display: true,
              type: "success",
              message: "Customer added successfully.",
            });
            this.$router.push({
              name: "AccountDetails",
              params: { entity: "customer", id },
            });
            if (this.isAddingPotentialCustomer) {
              await this.$store.dispatch("getGlobalCustomer", id);
              this.$store.commit("setCustomerDetails", this.customer);
              this.$store.commit("setShowAddPotentialPolicyModal", true);
            }
          }
        }
        this.closeModal();
      } catch (err) {
        const { errors } = err.response.data;
        this.specificErrors = this.parseFieldErrors(errors);
        this.isRequesting = false;
      }
    },
  },
};
</script>
