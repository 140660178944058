<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel modal__panel--termination">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>

      <div class="modal__main-icon modal__main-icon--potential"></div>
      <div class="modal__back" @click="goBack()"></div>
      <div class="modal__close" @click="closeModal()"></div>

      <div class="form form--terminate">
        <div class="navigators">
          <div class="navigator navigator--active">
            <div class="navigator__bullet"></div>
            <div class="navigator__title">
              {{ $t(`addPotentialPolicyModal.${"title"}`) }}
            </div>
          </div>
        </div>

        <div v-if="teamId == null" class="add__form add__form--terminate">
          <transition name="fade" mode="out-in">
            <Loading v-if="formLoading" />
            <div v-else class="add__content">
              <div class="termination">
                <div class="termination__row">
                  <div class="termination__column">
                    <div class="form__field" >
                      <VSelect 
                        class="select select--default" 
                        placeholder="Teams" 
                        :class="{'select--error': teamIdHasError }" 
                        :options="fetchedTeams" 
                        :reduce="(team) => team.id" 
                        :getOptionLabel="(team) => `${team.name}`" 
                        v-model="teamId" 
                        @input="fetchPolicyData(teamId)"
                      />
                      <div class="form__error" v-if="teamIdHasError">
                        Please select a team.   
                      </div>
                    </div>
                    <div class="form__field disabled-el">
                      <VSelect 
                        disabled
                        class="select select--default"
                        placeholder="Customer"
                      />
                       
                    </div>
                    <div class="form__field disabled-el">
                      <VSelect
                        disabled
                        class="select select--default" 
                        :placeholder="
                          $t(`addPotentialPolicyModal.${'agentDropdown'}`)
                        " 
                      /> 
                    </div>
                    <div class="form__field disabled-el">
                      <Datepicker
                        disabled
                        class="input input--default" 
                        :config="statics.datePickerConfig"
                        :placeholder="
                          $t(`addPotentialPolicyModal.${'dateDropdown'}`)
                        " 
                        v-model="date"
                      /> 
                    </div>
                  </div>
                  <div class="termination__column termination__column--middle">
                    <div class="form__field disabled-el">
                      <VSelect
                        disabled
                        class="select select--default"  
                        :placeholder="
                          $t(`addPotentialPolicyModal.${'categoryDropdown'}`)
                        " 
                      /> 
                    </div>
                    <div class="form__field disabled-el">
                      <VSelect
                        disabled
                        class="select select--default" 
                        :placeholder="
                          $t(`addPotentialPolicyModal.${'priorityDropdown'}`)
                        " 
                      />
                    </div>
                    <div class="form__field disabled-el">
                      <textarea
                        disabled
                        placeholder="Text"
                        cols="30"
                        rows="10"
                        class="  input--default termination__textarea" 
                      ></textarea>
                    </div>
                  </div>
                  <div class="termination__column">
                    <DropFile :disabled="true" @input="files = $event" />
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>

        <div v-else class="add__form add__form--terminate">
          <transition name="fade" mode="out-in">
            <Loading v-if="formLoading" />
            <div v-else class="add__content">
              <div class="termination">
                <div class="termination__row">
                  <div class="termination__column">
                    <div class="form__field" >
                      <div class="input input--default input--readonly">
                        {{ customerDetails.team.name }}
                      </div> 
                    </div>
                    <div class="form__field">
                      <VSelect
                        v-if="customerMembers.length"
                        class="select select--default"
                        :options="customerMembers"
                        :reduce="(member) => member.id"
                        :getOptionLabel="(member) => parseCustomerName(member)"
                        v-model="customerId"
                      />
                      <div v-else class="input input--default input--readonly">
                        {{ parseCustomerName(customerDetails) }}
                      </div>
                    </div>
                    <div class="form__field">
                      <VSelect
                        class="select select--default"
                        :class="{ 'select--error': agentHasError }"
                        :options="agents"
                        :reduce="(agent) => agent.id"
                        :getOptionLabel="
                          (agent) => `${agent.first_name} ${agent.last_name}`
                        "
                        :placeholder="
                          $t(`addPotentialPolicyModal.${'agentDropdown'}`)
                        "
                        v-model="agentId"
                      />
                      <div class="form__error" v-if="agentHasError">
                        {{ $t(`addPotentialPolicyModal.${"agentErrorMsg"}`) }}
                      </div>
                    </div>
                    <div class="form__field">
                      <Datepicker
                        class="input input--default"
                        :class="{ 'input--error': dateHasError }"
                        :config="statics.datePickerConfig"
                        :placeholder="
                          $t(`addPotentialPolicyModal.${'dateDropdown'}`)
                        "
                        v-model="date"
                      />
                      <div class="form__error" v-if="dateHasError">
                        {{ $t(`addPotentialPolicyModal.${"dateErrorMsg"}`) }}
                      </div>
                    </div>
                  </div>
                  <div class="termination__column termination__column--middle">
                    <div class="form__field">
                      <VSelect
                        class="select select--default"
                        :class="{ 'select--error': categoryHasError }"
                        :options="categories"
                        :reduce="(category) => category.id"
                        :label="`long_${language}`"
                        :placeholder="
                          $t(`addPotentialPolicyModal.${'categoryDropdown'}`)
                        "
                        v-model="branchCategoryId"
                      />
                      <div class="form__error" v-if="categoryHasError">
                        {{ $t(`addPotentialPolicyModal.${"categoryErrorMsg"}`) }}
                      </div>
                    </div>
                    <div class="form__field">
                      <VSelect
                        class="select select--default"
                        :options="priorities"
                        :reduce="(priority) => priority.id"
                        label="type"
                        :placeholder="
                          $t(`addPotentialPolicyModal.${'priorityDropdown'}`)
                        "
                        v-model="priorityId"
                      />
                    </div>
                    <div class="form__field">
                      <textarea
                        placeholder="Text"
                        cols="30"
                        rows="10"
                        class="input input--default termination__textarea"
                        v-model="text"
                      ></textarea>
                    </div>
                  </div>
                  <div class="termination__column">
                    <DropFile @input="files = $event" />
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div
        v-if="isRequesting"
        class="modal__button modal__button--submit button button--default button--loading"
      ></div>
      <div v-else>
        <div v-if="teamId == null" class="disabled-el">
          <button class="modal__button modal__button--submit button button--default">
            <span>{{ $t(`addPotentialPolicyModal.${"saveButton"}`) }}</span>
          </button>
        </div>
        <div v-else>
          <button
            class="modal__button modal__button--submit button button--default"
            @click="createPotentialPolicy()"
          >
            <span>{{ $t(`addPotentialPolicyModal.${"saveButton"}`) }}</span>
          </button>
        </div>
      </div>
     
    </div>
  </div>
</template>

<script>
import DropFile from "@/components/DropFile";
import Loading from "@/partials/Loading";
import { parseCustomerName } from "@/helpers/entityParsers";
import { mapGetters } from "vuex";
import statics from "@/statics";
export default {
  name: "AddPotentialPolicyModal",

  components: {
    DropFile,
    Loading,
  },

  data() {
    return {
      teamIdHasError: false,
      teamId: null,

      parseCustomerName,
      formLoading: true,
      isRequesting: false,
      statics,

      policyId: "",
      customerId: "",
      agentId: "",
      branchCategoryId: "",
      text: "",
      date: "",
      priorityId: "",
      files: [],

      agentHasError: false,
      dateHasError: false,
      categoryHasError: false,
    };
  },

  watch: {
    agentId(value) {
      if (value) {
        this.agentHasError = false;
      } else {
        this.agentHasError = true;
      }
    },
    date(value) {
      if (value) {
        this.dateHasError = false;
      } else {
        this.dateHasError = true;
      }
    },
    branchCategoryId(value) {
      if (value) {
        this.categoryHasError = false;
      } else {
        this.categoryHasError = true;
      }
    },
  },

  computed: {
    ...mapGetters([
      "customerDetails",
      "potentialPolicyForms",
      "customerMembers",
      "potentialPolicyToEdit",
      "language",
      "teams"
    ]),

    fetchedTeams() {
      return this.teams;
    },

    agents() {
      return this.potentialPolicyForms.agents;
    },

    categories() {
      return this.potentialPolicyForms.categories;
    },

    priorities() {
      return this.potentialPolicyForms.priorities;
    },
  },

  async created() {
    this.formLoading = true;

    await this.$store.dispatch("getTeams");

    this.customerId = Number(this.customerDetails.id);

    if(this.customerDetails.team){
      this.teamId = Number(this.customerDetails.team.id);
      this.fetchPolicyData(this.teamId)
    }

    if (Object.keys(this.potentialPolicyToEdit).length) {
      this.loadFormsToEdit();
    }

    this.formLoading = false;
  },


  destroyed() {
    this.$store.commit("setPotentialPolicyToEdit", {});
  },

  methods: {
    async fetchPolicyData(id){
      this.$store.commit("setAgents", null);
      
      this.customerId = this.customerDetails.id;

      if (Object.keys(this.potentialPolicyToEdit).length) {
        await this.$store.dispatch("getPotentialPolicyForms", id);
        this.agentId = null;
      }else{
        await this.$store.dispatch("getPotentialPolicyForms", id);
      }
    },

    closeModal() {
      this.$store.commit("setShowAddPotentialPolicyModal", false);
    },

    goBack() {
      this.closeModal();
      this.$store.commit("setShowAddCustomerServicesModal", true);
    },

    async loadFormsToEdit() {
      const {
        id,
        customer_id,
        agent: { id: agentId },
        category: { id: categoryId },
        text,
        date,
        priority: { id: priorityId },
        team_id
      } = this.potentialPolicyToEdit;

      await this.$store.dispatch("getPotentialPolicyForms", team_id);

      this.policyId = id;
      this.customerId = customer_id;
      this.agentId = agentId;
      this.branchCategoryId = categoryId;
      this.text = text;
      this.date = date;
      this.priorityId = priorityId;

      this.teamId = team_id; 
    },

    validateFields(callback) {
      if (this.agentId) {
        this.agentHasError = false;
      } else {
        this.agentHasError = true;
      }

      if (this.date) {
        this.dateHasError = false;
      } else {
        this.dateHasError = true;
      }

      if (this.branchCategoryId) {
        this.categoryHasError = false;
      } else {
        this.categoryHasError = true;
      }

      if (!this.agentHasError && !this.dateHasError && !this.categoryHasError) {
        callback(true);
      } else {
        callback(false);
      }
    },

    async createPotentialPolicy() {
      this.isRequesting = true;
      const teamId = this.teamId;
      this.validateFields(async (state) => {
        if (state) {
          let id = null;
          try {
            if (Object.keys(this.potentialPolicyToEdit).length) {
              const {
                data: {
                  data: { id: policyId },
                },
              } = await this.$store.dispatch("updatePotentialPolicy", {
                policyId: this.policyId,
                customer_id: this.customerId,
                agent_id: this.agentId,
                branch_category_id: this.branchCategoryId,
                text: this.text,
                date: this.date,
                priority_id: this.priorityId,
                team_id:teamId
              });
              id = policyId;
              this.$store.commit("setToast", {
                display: true,
                type: "success",
                message: "Potential Policy updated successfully.",
              });
            } else {
              const { data } = await this.$store.dispatch(
                "createPotentialPolicy",
                {
                  customerId: this.customerId,
                  agentId: this.agentId,
                  date: this.date,
                  branchCategoryId: this.branchCategoryId,
                  priorityId: this.priorityId,
                  text: this.text,
                  teamId
                }
              );
              const {
                data: { id: policyId },
              } = data;

              id = policyId;

              if(this.$route.path == '/dashboard'){
                try {
                  await this.$store.dispatch("getSalesDashboard"); 
                } catch (error) {
                  console.log('Error fetching dashboard details:', error); 
                }
              }

              this.$store.commit("setToast", {
                display: true,
                type: "success",
                message: "Potential Policy created successfully.",
              });



            }
          } catch (err) {
            this.closeModal();
            console.error(err);
          }

          if (this.files.length) {
            const files = this.files.map((file) => file.file);
            await this.$store.dispatch("addPotentialPolicyFile", {
              potentialPolicyId: id,
              files,
            });
          }
          await this.$store.dispatch(
            "getGlobalCustomer",
            this.customerDetails.id
          );
          this.closeModal();
        } else {
          this.isRequesting = false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/_termination-potential-policy.scss";

.termination__row {
  border: 0;
}

.add__content {
    margin-top: 0px !important;
}

.termination {
    padding-top: 0px; 
}
 
</style>
