<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel">
      <!-- <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div> -->

      <div class="modal__main-icon modal__main-icon--transaction"></div>
      <div class="modal__close" @click="closeModal()"></div>
      <div class="modal__title">{{ $t(`payoutReviewModal.${"title"}`) }}</div>

      <form class="form" @submit.prevent="createTransaction()">
        <div class="add__form">
          <div class="add__content">
            <div class="add__title">{{ $t(`payoutReviewModal.${"payoutQuestion"}`) }}</div>

            <div class="modal__report-info">
              <div class="modal__report-row">
                <div class="modal__report-column">
                  <div class="modal__report-row">
                    <div class="modal__report-column">Stornokonto Alt

                    </div>
                    <div class="modal__report-column">
                      {{ payoutReviewModalData.deposit_reserves_total + payoutReviewModalData.deposit }} CHF
                    </div>
                  </div>
                  <div class="modal__report-row">
                    <div class="modal__report-column">+</div>
                    <div class="modal__report-column">
                      {{
                        payoutReviewModalData.deposit * -1
                      }}
                      CHF
                    </div>
                  </div>
                  <div
                    class="modal__report-row modal__report-row--bold modal__report-row--margin"
                  >
                    <div class="modal__report-column">Stornokonto Neu</div>
                    <div class="modal__report-column">
                      {{ payoutReviewModalData.deposit_reserves_total  }} CHF
                    </div>
                  </div>
                </div>
                <div class="modal__report-seperator"></div>
                <div class="modal__report-column">
                  <div class="modal__report-row">
                    <div class="modal__report-column">Abschlussprovisionen</div>
                    <div class="modal__report-column">
                      {{ payoutReviewModalData.commissions }} CHF
                    </div>
                  </div>
                  <div class="modal__report-row">
                    <div class="modal__report-column">Courtagen</div>
                    <div class="modal__report-column">
                      {{ payoutReviewModalData.brokerFees }} CHF
                    </div>
                  </div>
                  <div class="modal__report-row">
                    <div class="modal__report-column">Sonstiges</div>
                    <div class="modal__report-column">
                      {{ payoutReviewModalData.others }} CHF
                    </div>
                  </div>
                  <div class="modal__report-row">
                    <div class="modal__report-column">Sonstiges</div>
                    <div class="modal__report-column">
                      {{ payoutReviewModalData.others }} CHF
                    </div>
                  </div>
                  <div class="modal__report-row">
                    <div class="modal__report-column">Stornokontoabzug</div>
                    <div class="modal__report-column">
                      {{ payoutReviewModalData.deposit }} CHF
                    </div>
                  </div>
                  <div class="modal__report-row">
                    <div class="modal__report-column">Stornokontoauzahlung</div>
                    <div class="modal__report-column">
                      {{ payoutReviewModalData.depositReturn }} CHF
                    </div>
                  </div>
                  <div class="modal__report-row">
                    <div class="modal__report-column">Bonus</div>
                    <div class="modal__report-column">
                      {{ payoutReviewModalData.bonus }} CHF
                    </div>
                  </div>
<!--                  <div class="modal__report-row" v-for="tax in payoutReviewModalData.taxes" v-bind:key="tax.id">
                    <div class="modal__report-column">{{tax.name}}</div>
                    <div class="modal__report-column">
                      {{ tax.value }} CHF


                    </div>
                  </div>-->
                    <div class="modal__report-row">
                    <div class="modal__report-column">AHV/IV - Beitrag</div>
                    <div class="modal__report-column">
                      {{ payoutReviewModalData.AHV }} CHF


                    </div>
                  </div>
                  <div class="modal__report-row">
                    <div class="modal__report-column">BVG - Beitrag</div>
                    <div class="modal__report-column">
                      {{ payoutReviewModalData.BVG }} CHF
                    </div>
                  </div>
                  <div class="modal__report-row modal__report-row--bold">
                    <div class="modal__report-column "> Neto provisionen</div>
                    <div class="modal__report-column ">
                      {{ payoutReviewModalData.subtotal }} CHF
                    </div>
                  </div>
                  <div class="modal__report-row">
                    <div class="modal__report-column">Spessen</div>
                    <div class="modal__report-column">
                      {{ payoutReviewModalData.expenses }} CHF
                    </div>
                  </div>
                  <div class="modal__report-row">
                    <div class="modal__report-column">Spezielle Auslagen</div>
                    <div class="modal__report-column">
                      {{ payoutReviewModalData.specialExpenses }} CHF
                    </div>
                  </div>
                  <div class="modal__report-row">
                    <div class="modal__report-column">Storno</div>
                    <div class="modal__report-column">
                      {{ payoutReviewModalData.storno }} CHF
                    </div>
                  </div>
                  <div class="modal__report-row modal__report-row">
                    <div class="modal__report-column">{{ $t(`payoutReviewModal.${"finishedPayout"}`) }}</div>
                    <div class="modal__report-column">
                      {{ payoutReviewModalData.finished_payout }} CHF
                    </div>
                  </div>
                  <div class="modal__report-row modal__report-row--bold">
                    <div class="modal__report-column">Auszahlungsbetrag</div>
                    <div class="modal__report-column">
                      {{ payoutReviewModalData.payout }} CHF
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__report-buttons" v-if="isAllowedToPay">
              <button
                type="button"
                class="button button--default button--no"
                @click="closeModal()"
              >
              {{ $t(`buttons.${"noBtn"}`) }}
              </button>
              <div
                v-if="isRequesting"
                class="button button--default button--loading"
              ></div>
              <button
                v-else
                type="button"
                @click="makePayment()"
                class="button button--default"
              >
              {{ $t(`buttons.${"yesBtn"}`) }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import api from "@/api";
export default {
  name: "PayoutReviewModal",

  data() {
    return {
      isRequesting: false,
      moment,
    };
  },

  destroyed() {
    this.$store.commit("setPayoutReviewModalData", {});
  },

  computed: {
    ...mapGetters(["payoutReviewModalData"]),

    isAllowedToPay() {
      // if (
      //   this.payoutReviewModalData.payout > 0
      // ) {
      //   return true;
      // }
      // return false;

      return true;
    },

    year() {
      return Number(this.payoutReviewModalData.period.split("-")[0]);
    },

    month() {
      return Number(this.payoutReviewModalData.period.split("-")[1]);
    },
  },

  methods: {
    closeModal() {
      this.$store.commit("setShowPayoutReviewModal", false);
    },
    openPayout: async (id) => {
      try {
        const response = await api({ requiresAuth: true }).get(
            `/v1/user-payouts/${id}/pdf`,
            {
              responseType: "blob",
            }
        );

        var fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: response.data.type })
        );
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
       // fileLink.setAttribute("download", file.file_name);
        document.body.appendChild(fileLink);

        if (
            response.data.type === "application/pdf" ||
            response.data.type === "image/jpeg" ||
            response.data.type === "image/png"
        ) {
          const { width, height } = screen;
          window.open(
              fileURL,
              "_blank",
              `location=yes,width=${width},height=${height},scrollbars=yes,status=yes`
          );
        } else {
          fileLink.click();
        }
        document.body.removeChild(fileLink);
      } catch (err) {
        console.error(err);
      }
    },

     async makePayment() { 
      if (this.isAllowedToPay) { 
        try {
          this.isRequesting = true;
          const {
             
            paidStatus,
            year,
            ahv,
            bvg,
            bvgValue,
            startDate,
            endDate, 
          } = this.$route.query;


          const team = Number(this.$route.query.teamId);

          const user = Number(this.$route.query.userId);

          let payload = {
            paid_status: paidStatus === "true",
            user_id: user,
            year: year,
            taxes: this.payoutReviewModalData.taxes,
            ahv: ahv === "true",
            bvg: bvg === "true",
            bvg_value: bvg === "true" ? bvgValue : 0,
            team_id: team
          }; 

          if (startDate && endDate) {
            payload = {
              ...payload,
              start_date: startDate,
              end_date: endDate,
            };
          } 
          await this.$store.dispatch("makePayment", {
            ...payload,
            month: Number(this.payoutReviewModalData.period.split("-")[1]),
          }).then(
              async (response) => {
                const { data } = response.data;

                await this.openPayout(data.id);
              }
          );
          await this.$store.dispatch("getReports", team);
          this.closeModal();
        } catch (err) {
          this.isRequesting = false;
          console.error(err);
        }
      }
    },
  },
  mounted(){
    console.log(this.payoutReviewModalData.period)
  }
};
</script>

<style scoped lang="scss">
.modal__panel{
  width: 1100px;
    height: 780px;
}

.modal {
  &__title {
    font-size: var(--medium);
    color: var(--text-2);
    margin-left: 74px;
  }

  &__report {
    &-info {
      background: var(--bg-16); 
      padding: 40px 20px;
      margin-top: 40px;
      border-radius: var(--radius-1);
    }

    &-row {
      display: flex;
      color: var(--text-2);

      &--bold {
        color: var(--text-9);

        .modal__report-column {
          font-family: var(--muli-text-bold);
        }
      }

      &--margin {
        margin-top: 15px;
      }
    }

    &-column {
      padding: 5px;
      flex: 1;
      text-align: right;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; 
    }

    &-buttons {
      display: flex;
      justify-content: center;
      margin-top: 60px;

      .button {
        width: 135px;
        height: 40px;

        &--no {
          margin-right: 116px;
          background: var(--bg-18) !important;
          box-shadow: 0px 7px 18px var(--shadow-11) !important;

          &:hover {
            background: var(--hover-3) !important;
            box-shadow: 0px 0px 20px var(--shadow-11) !important;
          }
        }
      }
    }

    &-seperator {
      height: 170px;
      width: 1px;
      background: var(--border-17);
      margin-left: 20px;
    }
  }
}

.add {
  &__form {
    width: 100% !important;
    height: calc(100% - 18px) !important;
  }

  &__content {
    padding: 0 20px !important;
  }

  &__title {
    margin-top: 44px;
    text-align: center;
    font-family: var(--muli-text-semi-bold);
    font-size: var(--medium);
    color: var(--text-5);
  }
}
</style>
