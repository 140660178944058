<template>
  <div class="empty" ref="empty" @mousemove="moveCard($event)">
    <div class="empty__content">
      <div
        class="empty__graphics"
        :style="{
          transform: `rotateY(${x}deg) rotateX(${y}deg)`,
        }"
      >
        <img
          class="empty__image empty__image--1"
          src="../assets/icons/default/Background.svg"
          alt=""
        />
        <img
          class="empty__image empty__image--2"
          src="../assets/icons/default/back_page.svg"
          alt=""
        />
        <img
          v-if="type === 'users' || type === 'customers'"
          class="empty__image empty__image--3"
          src="../assets/icons/default/front_page_customers.svg"
          alt=""
        /> 
        <img
        v-if="type === 'notifications' || type === 'authLogs'" 
          class="empty__image empty__image--3" 
          src="../assets/icons/default/empty_layout.svg"
          alt=""
        /> 
        <img
          v-else-if="type === 'teams'"
          class="empty__image empty__image--3"
          src="../assets/icons/default/no_team_selected.svg"
          alt=""
        />
        <img
          v-else-if="type === 'models'"
          class="empty__image empty__image--3"
          src="../assets/icons/default/front_page_models.svg"
          alt=""
        />
        <img
          v-else-if="type === 'policies'"
          class="empty__image empty__image--3"
          src="../assets/icons/default/front_page_policies.svg"
          alt=""
        />
        <img
          v-else-if="type === 'potential-policies' || type === 'potential-policies-dashboard'"
          class="empty__image empty__image--3"
          src="../assets/icons/default/front_page_policies.svg"
          alt=""
        />
        <img
          v-else-if="type === 'reports' || type === 'policiesList'"
          class="empty__image empty__image--3"
          src="../assets/icons/default/front_page_report.svg"
          alt=""
        />
        <!-- <img
          v-else-if="type === 'pendencies'"
          class="empty__image empty__image--3"
          src="../assets/icons/default/no_pendencies_icon.svg"
          alt=""
        /> -->
        <img
          v-else-if="type === 'pendencies'"
          class="empty__image empty__image--3"
          src="../assets/icons/default/no_pendencies_icon.svg"
          alt=""
        />
        <img
          v-else-if="type === 'notes'"
          class="empty__image empty__image--3"
          src="../assets/icons/default/no_notes_icon.svg"
          alt=""
        />
        <img
          v-else-if="type === 'calls'"
          class="empty__image empty__image--3"
          src="../assets/icons/default/no_calls_icon.svg"
          alt=""
        />
        <div class="empty__active" ref="active"></div>
      </div>

      <div class="empty__title" v-if="type === 'users'">
        {{ $t(`noData.${"noUsersMessage"}`) }}
      </div>
      <div class="empty__title" v-else-if="type === 'teams'">
        {{ $t(`noData.${"noSelectedTeamMessage"}`) }}
      </div>
      <div class="empty__title" v-else-if="type === 'customers'">
        {{ $t(`noData.${"noCustomersMessage"}`) }}
      </div>
      <div class="empty__title" v-else-if="type === 'policies'">
        {{ $t(`noData.${"noPoliciesMessage"}`) }} 
      </div>
      <div class="empty__title" v-else-if="type === 'potential-policies' || type === 'potential-policies-dashboard'">
        {{ $t(`noData.${"noPotentialPolicyMessage"}`) }} 
      </div> 
      <div class="empty__title" v-else-if="type === 'models'">
        {{ $t(`noData.${"noModelsMessage"}`) }} 
      </div>
      <div class="empty__title" v-else-if="type === 'reports'">
        {{ $t(`noData.${"noReportsMessage"}`) }} 
      </div>
      <div class="empty__title" v-else-if="type === 'policiesList'">
        {{ $t(`noData.${"noPoliciesMessage"}`) }} 
      </div>
      <div class="empty__title" v-else-if="type === 'pendencies' || type === 'dashboard-pendencies' "> 
        {{ $t(`noData.${"noPendencyMessage"}`) }} 
      </div>
      <div class="empty__title" v-else-if="type === 'notes'">
        {{ $t(`noData.${"noNotesMessage"}`) }} 
      </div>
      <div class="empty__title" v-else-if="type === 'calls'">
        {{ $t(`noData.${"noCallsMessage"}`) }}
      </div>
      <div class="empty__title" v-else-if="type === 'no-permission' "> 
        {{ $t(`noData.${"noPermissionMessage"}`) }} 
      </div>

 
      <div class="empty__title" v-else-if="type === 'notifications' "> 
        {{ $t(`noData.${"noNotifications"}`) }} 
      </div>

      <div class="empty__title" v-else-if="type === 'authLogs' "> 
        {{ $t(`noData.${"noAuthLogs"}`) }} 
      </div>
    
      
      <button
        type="button"
        class="empty__button button button--default button--shadow"
        v-if="type === 'users'"
        @click="$store.commit('setShowAddUserModal', true)"
      > 
        {{ $t(`noData.${"addNewUserButton"}`) }}
      </button>
      <button
        type="button"
        class="empty__button button button--default button--shadow"
        v-else-if="type === 'customers'"
        @click="openCustomerModal()"
      >
        {{ $t(`noData.${"addNewCustomerButton"}`) }}
      </button>
      <button
        type="button"
        class="empty__button button button--default button--shadow"
        v-else-if="type === 'policies'"
        @click="$store.commit('setShowAddPolicyModal', true)"
      >
        {{ $t(`noData.${"addNewPolicyButton"}`) }}
      </button>
      <button
        type="button"
        class="empty__button button button--default button--shadow"
        v-else-if="type === 'potential-policies'"
        @click="$store.commit('setShowAddPotentialPolicyModal', true)"
      >
        {{ $t(`noData.${"addnewPotentialPolicy"}`) }} 
      </button>
      
      <button
        type="button"
        class="empty__button button button--default button--shadow"
        v-else-if="type === 'potential-policies-dashboard'"
        @click="$store.commit('setShowSearchPotentialCustomerModal', true)"
      >
        {{ $t(`noData.${"addnewPotentialPolicy"}`) }} 
      </button>
      <button
        type="button"
        class="empty__button button button--default button--shadow"
        v-else-if="type === 'models'"
        @click="$store.commit('setShowAddModelModal', true)"
      >
        {{ $t(`noData.${"addNewModelButton"}`) }}
      </button>

      <button
        type="button"
        class="empty__button button button--default button--shadow"
        v-else-if="type === 'pendencies'"
        @click="$store.commit('setShowAddPendencyModal', true)"
      >
        {{ $t(`noData.${"addnewPendencyButton"}`) }} 
      </button>

      <button
        type="button"
        class="empty__button button button--default button--shadow"
        v-else-if="type === 'notes'"
        @click="$store.commit('setShowAddNotesModal', true)"
      >
        {{ $t(`noData.${"addnewNoteButton"}`) }}   
      </button>

      <button
        type="button"
        class="empty__button button button--default button--shadow"
        v-else-if="type === 'calls'"
        @click="$store.commit('setShowAddCallsModal', true)"
      >
        {{ $t(`noData.${"addnewCallButton"}`) }}    
      </button>

    </div>
  </div>
</template>

<script>
export default {
  name: "NoData",

  props: {
    type: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      x: 0,
      y: 0,
    };
  },

  methods: {
    moveCard(event) {
      if (this.$refs.empty && event.target === this.$refs.active) {
        this.x =
          (this.$refs.empty.clientWidth / 2 -
            (event.clientX -
              (window.innerWidth - this.$refs.empty.clientWidth))) /
          30;
        this.y =
          (this.$refs.empty.clientHeight / 2 -
            (event.clientY -
              (window.innerHeight - this.$refs.empty.clientHeight))) /
          25;
      }
      return;
    },

    openCustomerModal() {
      this.$store.commit("setIsAddingCustomer", true);
      this.$store.commit("setShowAddCustomerModal", true);
    },
  },
};
</script>

<style scoped lang="scss">
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &__title {
    max-width: 310px;
    text-align: center;
    color: var(--text-2);
    font-size: var(--medium);
    line-height: 23px;
    margin: 35px 0 80px 0;
  }

  &__graphics {
    transform-style: preserve-3d;
    position: relative;

    &:hover {
      .empty__image--2 {
        transform: translate(-50%, -50%) translateZ(150px) scale(1.1);
      }
      .empty__image--3 {
        transform: translate(calc(-50% + 40px), -50%) translateZ(250px)
          scale(1.1) rotate(20deg);
      }
    }
  }

  &__image {
    position: absolute;
    left: 50%;
    top: 50%;
    transition: transform 0.2s ease-in-out;

    &--1 {
      width: 285px;
      position: static;
    }

    &--2 {
      width: 130px;
      transform: translate(-50%, -50%);
    }

    &--3 {
      width: 115px;
      top: 30px;
      transform: translate(calc(-50% + 20px), -50%);
    }
  }

  &__active {
    position: absolute;
    width: calc(100% + 200px);
    height: calc(100% + 200px);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) translateZ(300px);
  }

  &__button {
    margin: auto;
    padding: 0 20px;
    border-radius: var(--radius-1);
  }
}
</style>
